import {
  Button,
  Caption1,
  makeStyles,
  shorthands,
  tokens,
  Tooltip,
  Text,
  mergeClasses,
  Subtitle2,
  Title3,
  Caption1Stronger,
} from "@fluentui/react-components";
import { InfoRegular, Delete16Regular } from "@fluentui/react-icons";
import { WalterApi } from "@src/lib/walter/api";
import { type UserDefinedFieldSet } from "@src/lib/walter/fieldSets";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import { useFieldSets } from "@src/taskpane/hooks/useFieldSets";
import useToasts from "@src/taskpane/hooks/useToasts";
import { useHistory } from "react-router-dom";

export const useClasses = makeStyles({
  iconInfo: {
    color: tokens.colorBrandForeground2,
    fontSize: "1rem",
  },
  hgroup: {
    marginTop: "12px",
    display: "flex",
    alignItems: "start",
    ...shorthands.gap("4px"),
  },
  pageTitle: {
    ...shorthands.margin(0),
    lineHeight: "1.0",
  },
});

export const useClassesForList = makeStyles({
  sectionHeader: {
    marginBottom: "4px",
  },
  textWrapper: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
    height: "32px",
    ...shorthands.overflow("hidden"),
    ...shorthands.gap("4px"),
  },
  textItem: {
    height: "min-content",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
  listItem: {
    ...shorthands.padding("0.25rem", "0.25rem", "0.25rem", "0.5rem"),
    "> div > span": {
      ":nth-child(2)": {
        display: "none",
      },
    },
    ":hover": {
      backgroundColor: tokens.colorSubtleBackgroundHover,
      "> div > span": {
        ":nth-child(2)": {
          display: "inline-flex",
        },
      },
    },
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    ...shorthands.gap("4px"),
    justifyContent: "space-between",
  },
  deleteButton: {
    maxWidth: "min-content",
  },
});

const FieldSetList = ({
  header,
  fieldSets,
  setFieldSets,
}: {
  header: string;
  fieldSets: UserDefinedFieldSet[];
  setFieldSets: (fieldSets: UserDefinedFieldSet[]) => void;
}) => {
  const classes = useClassesForList();
  const { showErrors, showSuccess } = useToasts();

  async function deleteFieldSet(fieldSetId: number) {
    const { response } = WalterApi.deleteFieldSet(fieldSetId);

    try {
      const result = await response;

      // If the field set was successfully deleted, remove it from the list.
      // In the case of a 404, the field set was already deleted and we can remove it from the list.
      if (!result?.error || result?.status === 404) {
        showSuccess("Field set removed.");

        setFieldSets(fieldSets.filter((fieldSet) => fieldSet.id !== fieldSetId));
        return;
      }

      showErrors({ title: "Error deleting field set", messages: [result.error] });
      captureErrorInfo(result.error);
    } catch (e) {
      captureErrorInfo(e);
      showErrors({
        title: "Error deleting field set",
        messages: ["An error occurred while deleting the field set. Try again."],
      });
    }
  }

  return (
    <section>
      <Subtitle2 className={classes.sectionHeader}>{header}</Subtitle2>
      <ul>
        {fieldSets.map(({ id, label, description }) => (
          <li key={id} className={mergeClasses(classes.flexContainer, classes.listItem)}>
            <div className={mergeClasses(classes.flexContainer, classes.textWrapper)}>
              <Text className={classes.textWrapper}>
                <Caption1Stronger>Label: </Caption1Stronger>
                <Caption1 className={classes.textItem}>{label}</Caption1>
              </Text>
              {description ? (
                <Text className={mergeClasses(classes.textWrapper, classes.textItem)}>
                  <Caption1Stronger>Description: </Caption1Stronger>
                  <Caption1 className={classes.textItem}>{description}</Caption1>
                </Text>
              ) : null}
            </div>

            <Button
              className={classes.deleteButton}
              onClick={() => deleteFieldSet(id)}
              icon={<Delete16Regular aria-label="Delete field set" />}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

const ManageFieldSets = () => {
  const classes = useClasses();
  const fieldSets = useFieldSets();
  const history = useHistory();

  return (
    <>
      <hgroup className={classes.hgroup}>
        <Title3 className={classes.pageTitle}>Field Sets</Title3>
        <Tooltip
          content="Field sets are groups of fields which are available to insert to a document. They will be substituted with the actual data when the document is assembled in Walter."
          relationship="description"
        >
          <InfoRegular className={classes.iconInfo} aria-label="Info about field sets" />
        </Tooltip>
      </hgroup>

      {fieldSets.userPersonalFieldSets.length === 0 && fieldSets.workspaceFieldSets.length === 0 ? (
        <>
          <p>Looks like you've not created any field sets yet.</p>
          <Button onClick={() => history.push("/field-sets/new")}>Create a field set</Button>
        </>
      ) : null}

      {fieldSets.userPersonalFieldSets.length > 0 ? (
        <FieldSetList
          header="Personal sets"
          fieldSets={fieldSets.userPersonalFieldSets}
          setFieldSets={fieldSets.setUserPersonalFieldSets}
        />
      ) : null}
      {fieldSets.workspaceFieldSets.length > 0 ? (
        <FieldSetList
          header="Workspace sets"
          fieldSets={fieldSets.workspaceFieldSets}
          setFieldSets={fieldSets.setWorkspaceFieldSets}
        />
      ) : null}
    </>
  );
};

export default ManageFieldSets;
