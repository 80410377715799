import { captureException } from "@sentry/react";
import debug from "@src/lib/debug";

function captureErrorInfo(
  e: Parameters<typeof captureException>[0],
  extraContext?: Parameters<typeof captureException>[1],
) {
  debug.error(e);

  if (!extraContext && window?.OfficeExtension?.Error && e instanceof OfficeExtension.Error) {
    extraContext = {
      extra: {
        traceMessages: e.traceMessages,
        debugInfo: e.debugInfo,
        innerError: e.innerError,
      },
    };
  }

  if (extraContext) {
    debug.error("Extra error context: ", extraContext);

    captureException(e, extraContext);
  } else {
    captureException(e);
  }
}

export { captureErrorInfo };
