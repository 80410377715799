import {
  Button,
  Caption1,
  Input,
  Label,
  useId,
  makeStyles,
  shorthands,
  Switch,
  Tooltip,
  Title3,
  Caption2,
  Text,
} from "@fluentui/react-components";
import { WalterApi } from "@src/lib/walter/api";
import { useFields } from "@src/taskpane/hooks/useFields";
import React, { FunctionComponent, useState, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import { useClasses } from "./ManageFieldSets";
import { InfoRegular, BracesVariableRegular } from "@fluentui/react-icons";
import { CollapsibleSection } from "@src/taskpane/components/CollapsibleSection";
import useToasts from "@src/taskpane/hooks/useToasts";
import { Field, documentSchemaStore } from "@src/lib/schemas";

const formStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("20px"),
    // Stack the label above the field (with 2px gap per the design system)
    "> div": {
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("2px"),
    },
  },
});

const useListClasses = makeStyles({
  listItem: {
    paddingLeft: "5px",
    display: "flex",
    alignItems: "center",
    minHeight: "32px",
    boxSizing: "border-box",
    gridRowStart: "layout",
    gridColumnStart: "layout",
    gridRowEnd: "layout",
    gridColumnEnd: "layout",
    ...shorthands.gap("4px"),
  },
  list: {
    marginTop: "0",
  },
  icon: {
    minWidth: "16px",
  },
  text: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
});

const FieldsList = ({ fields }: { fields: Field[] }) => {
  const classes = useListClasses();

  return (
    <ul className={classes.list}>
      {fields.map((field) => (
        <li className={classes.listItem} key={field.key}>
          <BracesVariableRegular fontSize={16} className={classes.icon} />
          <Text className={classes.text}>
            <Caption1>{field.label}</Caption1>
            {field.node.ref || field.node.type ? <Caption2>({field.node.ref || field.node.type})</Caption2> : null}
          </Text>
        </li>
      ))}
    </ul>
  );
};

export const NewFieldSet: FunctionComponent = () => {
  const [label, setLabel] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const history = useHistory();
  const isIncomplete = label === "";
  const { fields } = useFields();
  const classes = useClasses();
  const { showErrors, showSuccess } = useToasts();

  const nameInputId = useId("name");
  const descriptionInputId = useId("description");

  const [isVisibleToWorkspace, setIsVisibleToWorkspace] = React.useState(true);
  const onChange = React.useCallback(
    (ev) => {
      setIsVisibleToWorkspace(ev.currentTarget.checked);
    },
    [setIsVisibleToWorkspace],
  );

  const form = formStyles();

  const onLabelChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  };

  const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const saveFieldSet = async () => {
    const schema = { ...documentSchemaStore.schema, title: label, description: description ?? "" };
    // Save the field set
    const { response } = WalterApi.saveFieldSet({
      label,
      description,
      owner_type: isVisibleToWorkspace ? "workspace" : "user",
      schema,
    });

    const result = await response;

    if (!result) {
      showErrors({ title: "Failed to save field set" });
      return;
    }

    return await parseSaveResponseBody(result);
  };

  async function parseSaveResponseBody(result: { errors?: string[]; id?: number }) {
    if (result.errors) {
      showErrors({ title: "Failed to save field set", messages: result.errors });
      return;
    } else {
      history.push("/");
      showSuccess("Field set saved");
    }
  }

  const onSubmit = async () => {
    await saveFieldSet();
  };

  return (
    <>
      <hgroup className={classes.hgroup}>
        <Title3 className={classes.pageTitle}>Create a field set</Title3>
        <Tooltip
          content="Field sets are collections of fields that can be used to create templates. Field sets can be shared with your workspace or kept private."
          relationship="description"
        >
          <InfoRegular className={classes.iconInfo} aria-label="Info about field sets" />
        </Tooltip>
      </hgroup>
      <form className={form.root}>
        <div>
          <Switch checked={isVisibleToWorkspace} onChange={onChange} label="Visible to workspace" autoFocus={true} />
          <div>
            {isVisibleToWorkspace ? (
              <Caption1>This field set will be visible to everyone in your workspace</Caption1>
            ) : (
              <Caption1>This field set will be only visible to you</Caption1>
            )}
          </div>
        </div>
        <div>
          <Label weight="semibold" htmlFor={nameInputId}>
            Label <em>(required)</em>
          </Label>
          <Input id={nameInputId} type="text" onChange={onLabelChange} value={label} />
          <Caption1>The label provides a simple way to identify this field set in the future.</Caption1>
        </div>
        <div>
          <Label weight="semibold" htmlFor={descriptionInputId}>
            Description
          </Label>
          <Input id={descriptionInputId} type="text" onChange={onDescriptionChange} value={description} />
          <Caption1>Field set label will be displayed when selecting a field set from the Field sets dropdown</Caption1>
        </div>
      </form>

      <p>The field set will be saved with the following fields:</p>
      <CollapsibleSection title="Fields">
        <FieldsList fields={fields} />
      </CollapsibleSection>
      <Button appearance="primary" onClick={onSubmit} disabled={isIncomplete}>
        Save
      </Button>
    </>
  );
};
