import { Spinner } from "@fluentui/react-components";

export interface ProgressProps {
  message: string;
}

const Progress = ({ message }: { message: string }) => {
  return (
    <div
      style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center", padding: "12px" }}
      data-testId="progress-spinner"
    >
      <Spinner appearance="primary" size="small" labelPosition="below" label={message} />
    </div>
  );
};

export default Progress;
