import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { tokenStorage } from "@src/lib/settings/tokenStorage";
import { defaultStorage } from "@src/lib/settings/defaultStorage";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import { UserWithRecents } from "@src/lib/auth/OAuthService";

let globalInitialized = false;
export function initialized() {
  globalInitialized = true;
  window.dispatchEvent(new Event("walter.initialized"));
}

/**
 * Hook to check if Walter Add-In has been initialized.
 *
 * This includes:
 * - Initializing Office.js
 * - Loading dynamic elements from the document
 * - Running init tasks (like re-saving dynamic elements)
 */
export const useInitialized = (setIsLoggedIn?: (loggedIn: boolean) => void) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (globalInitialized) setInitialized(true);

    async function fetchToken() {
      const token = await tokenStorage.get("walter");

      if (token) {
        setIsLoggedIn?.(true);
        const currentUser = defaultStorage.get<UserWithRecents>("currentUser");
        const scope = Sentry.getCurrentScope();
        scope.clear();
        scope.setUser({
          id: currentUser?.id,
          email: currentUser?.email,
        });
      }
    }

    fetchToken().catch(captureErrorInfo);

    const handleInitialized = () => setInitialized(true);
    window.addEventListener("walter.initialized", handleInitialized);
    return () => window.removeEventListener("walter.initialized", handleInitialized);
  }, []);

  return initialized;
};
