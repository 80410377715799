import { FC, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router-dom";
import { ChevronCircleDown24Regular } from "@fluentui/react-icons";

import { Migration } from "@src/lib/migrations/Migration";
import { DynamicElement } from "@src/lib/liquidx/internal";
import { useNewUi } from "../hooks/useNewUi";
import {
  Button,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  makeStyles,
  tokens,
  shorthands,
  Link,
} from "@fluentui/react-components";
import { WalterContext } from "../providers/walter";
import useToasts from "@src/taskpane/hooks/useToasts";
import { tokenStorage } from "@src/lib/settings/tokenStorage";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import { revokeToken } from "@src/lib/auth/OAuthService";
import { defaultStorage } from "@src/lib/settings/defaultStorage";

export const useLogoStyles = makeStyles({
  root: {
    width: "1.5rem",
    height: "auto",
  },
  icon: {
    width: "1.0rem",
    height: "auto",
  },
});

const useMenuStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "0.5rem",
    paddingRight: "24px",
    paddingBottom: "0.5rem",
    paddingLeft: "24px",
    position: "sticky",
    top: 0,
    backgroundColor: tokens.colorNeutralBackground1,
    zIndex: 100,
    ...shorthands.borderBottom("1px", "solid", "lightgray"),
  },
});

type TopNavProps = {
  setIsLoggedIn: (loggedIn: boolean) => void;
};

const TopNav: FC<TopNavProps> = ({ setIsLoggedIn }) => {
  const history = useHistory();
  const { showNewUi } = useNewUi();
  const menuStyles = useMenuStyles();
  const logoStyles = useLogoStyles();
  const { isFieldSetSelected } = useContext(WalterContext);
  const isChoosingFieldSet = location.pathname === "/choose-field-set";
  const { showErrors } = useToasts();

  function navToManageFieldSets() {
    history.push("/field-sets");
  }

  function navToFieldSetSelection() {
    history.push("/choose-field-set");
  }

  function clearCurrentUserInfo() {
    defaultStorage.clear();

    Sentry.configureScope((scope) => {
      scope.clear();
    });
  }

  async function signOut() {
    const token = await tokenStorage.get("walter");

    if (token) {
      try {
        await revokeToken(token.accessToken);
        setIsLoggedIn(false);
        clearCurrentUserInfo();
      } catch (e: unknown) {
        captureErrorInfo(e);

        showErrors({
          title: "Error logging you out",
          messages: import.meta.env.DEV ? [(e as Error).message] : [],
          footerContent: <Link onClick={() => signOut()}>Try again</Link>,
        });
      }
    } else {
      // if for some reason the user appeared logged in even though they did not have an access token
      // set logged in as false and clear the user info so that they get out of this bad state in the UI.
      setIsLoggedIn(false);
      clearCurrentUserInfo();
    }
  }

  if (showNewUi) {
    return null;
  }

  return (
    <div className={menuStyles.root}>
      <img
        className={logoStyles.root}
        src="https://assets.website-files.com/6466932b2078b80cd650a863/64e91971ecbf264430c05dba_Walter_Icon_256px.png"
        alt="Walter"
      />
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Button appearance="transparent" data-testid="layout-menu-button" icon={<ChevronCircleDown24Regular />} />
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            {!isChoosingFieldSet && isFieldSetSelected ? (
              <MenuItem onClick={navToFieldSetSelection}>Change field set</MenuItem>
            ) : null}
            <MenuItem onClick={navToManageFieldSets}>Manage field sets</MenuItem>
            <MenuItem onClick={() => void DynamicElement.resave()}>Refresh elements</MenuItem>
            {import.meta.env.DEV && (
              <MenuItem onClick={async () => await Migration.setAppliedVersions([])}>Reset migrations</MenuItem>
            )}
            <MenuItem onClick={() => signOut()}>Sign out</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
};

export default TopNav;
