import App from "./components/App";
import { HashRouter } from "react-router-dom";
import { FluentProvider, webLightTheme, webDarkTheme } from "@fluentui/react-components";
import { FC, useEffect, useState } from "react";
import { WalterProvider } from "./providers/walter";
import * as Sentry from "@sentry/react";
import { syncEngine } from "@src/lib/sync-engine/SyncEngine";
import { initialized } from "./hooks/useInitialized";
import { DocumentSettings } from "@src/lib/settings/DocumentSettings";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import { documentSchemaStore } from "@src/lib/schemas";
import { inputOptionsHandler } from "@src/lib/walter";
import { migrate } from "@src/lib/migrations/migrate";
import { DynamicElement } from "@src/lib/liquidx/internal";
import { WaitUntilCustomXmlPartsAreReady } from "@src/lib/sync-engine/operations/WaitUntilCustomXmlPartsAreReady";

export interface OnOfficeReadyInfo {
  platform: Office.PlatformType;
  host: Office.HostType;
}

export type OnOfficeReadyCallback = (info: OnOfficeReadyInfo) => any;
export type OnOfficeReady = (callback?: OnOfficeReadyCallback) => Promise<OnOfficeReadyInfo>;
interface Props {
  onOfficeReady: OnOfficeReady;
}

const DEFAULT_ERROR_HEADER = "Something went wrong";
const DEFAULT_ERROR_TEXT = "Please reload the page or contact support.";

const setError = (header = DEFAULT_ERROR_HEADER, innerText = DEFAULT_ERROR_TEXT) => {
  const loadHeader = document.getElementById("load-screen-header");
  const loadText = document.getElementById("load-screen-text");

  if (loadHeader) loadHeader.innerText = header;
  if (loadText) loadText.innerText = innerText;
};

export const AppShell: FC<Props> = ({ onOfficeReady }) => {
  // Switch themes based on system theme
  const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
  const [systemTheme, setSystemTheme] = useState(isDarkMode.matches ? webDarkTheme : webLightTheme);

  useEffect(() => {
    void onOfficeReady(({ platform }) => {
      Sentry.setTag("platform", platform);
      if (typeof OfficeExtension !== "undefined") OfficeExtension.config.extendedErrorLogging = true;

      async function initialize() {
        try {
          await syncEngine.run(async () => {
            await syncEngine.perform(new WaitUntilCustomXmlPartsAreReady());
            await migrate("local");
            // By default, show the taskpane when the document is opened.
            const autoShow = await DocumentSettings.get("Office.AutoShowTaskpaneWithDocument");
            await DocumentSettings.set("Office.AutoShowTaskpaneWithDocument", autoShow ?? true);
            await documentSchemaStore.load();
            inputOptionsHandler.load(documentSchemaStore.schema);
            await DynamicElement.load();
            await DynamicElement.revalidate();

            initialized();
          });
        } catch (error) {
          if (
            error instanceof ReferenceError &&
            error.message === "OfficeExtension is not defined" &&
            platform === null
          ) {
            setError("Please open Add-in in Word", "Can't load Add-in outside of Word.");
          } else {
            captureErrorInfo(error);
            setError();
          }
        }
      }

      void initialize();
    }).catch((error) => {
      captureErrorInfo(error);
      setError();
    });
  }, []);

  useEffect(() => {
    const handleChange = (e: MediaQueryListEvent) => setSystemTheme(e.matches ? webDarkTheme : webLightTheme);
    isDarkMode.addEventListener("change", handleChange);
    return () => isDarkMode.removeEventListener("change", handleChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- isDarkMode won't change
  }, []);

  return (
    <FluentProvider theme={systemTheme}>
      <HashRouter>
        <WalterProvider>
          <App />
        </WalterProvider>
      </HashRouter>
    </FluentProvider>
  );
};
