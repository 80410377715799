import { useEffect, useState } from "react";
import { useDynamicElements } from "./useDynamicElements";
import { JsElement } from "@src/lib/liquidx/internal";
import { FeatureFlagManager } from "@src/taskpane/hooks/useFeatureFlags";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";

export const useNewUi = () => {
  const featureFlagName = "js_templates_word_add_in";
  const { dynamicElements } = useDynamicElements();
  const [showNewUi, setShowNewUi] = useState(false);
  const [showMigrateButton, setShowMigrateButton] = useState(false);
  const featureFlagManager = FeatureFlagManager.getInstance();

  useEffect(() => {
    return () => {
      featureFlagManager.abortController?.abort("Component unmounted. Aborting feature flag request.");
    };
  }, []);

  useEffect(() => {
    async function doThing() {
      let enabled = true;
      try {
        enabled = (await featureFlagManager.isEnabled(featureFlagName)) ?? true;
      } catch (e) {
        captureErrorInfo(e);
        enabled = true;
      }

      const hasJsElements = dynamicElements.some((element) => element instanceof JsElement);
      const hasNoElements = dynamicElements.length === 0;
      const updatedShowNewUi = hasNoElements || hasJsElements;

      setShowNewUi(enabled && updatedShowNewUi);
      setShowMigrateButton(enabled && !updatedShowNewUi);
    }

    void doThing();
  }, [dynamicElements]);

  return {
    showNewUi,
    showMigrateButton,
  };
};
