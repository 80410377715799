import { ADD_IN_HOST } from "@src/lib/env-vars";
import { generatePreviewUrl } from "@src/preview_dialog/lib";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";

export async function openPreviewDialog(config: {
  onError: (e: unknown) => void;
}): Promise<Office.AsyncResult<Office.Dialog>> {
  let iframePreviewUrl: string;

  try {
    iframePreviewUrl = await generatePreviewUrl();
  } catch (e: unknown) {
    config.onError(e);
    throw e;
  }

  const previewUrl = `${ADD_IN_HOST}/preview_dialog.html?preview_page_url=${encodeURIComponent(iframePreviewUrl)}`;

  const asyncResult = await new Promise<Office.AsyncResult<Office.Dialog>>((resolve, reject) => {
    Office.context.ui.displayDialogAsync(
      previewUrl,
      { height: 80, width: 60, displayInIframe: false },
      async (asyncDialog) => {
        try {
          resolve(await displayDialogCallback(asyncDialog));
        } catch (e) {
          reject(e);
        }
      },
    );
  });

  return asyncResult;
}

export async function displayDialogCallback(
  asyncResult: Office.AsyncResult<Office.Dialog>,
): Promise<Office.AsyncResult<Office.Dialog>> {
  function reportError(errorMessage: string = "Something went wrong") {
    // Report full detail to Sentry
    const data = JSON.stringify(asyncResult, null, 2);
    const error = new Error(`Error while opening preview dialog: ${data}`);
    captureErrorInfo(error);

    // Report error to user
    throw errorMessage;
  }

  if (asyncResult.error) {
    switch (asyncResult.error.code) {
      // https://learn.microsoft.com/en-us/office/dev/add-ins/reference/javascript-api-for-office-error-codes#error-codes
      case 12006:
        // The dialog box has been closed. Thrown by call of displayDialogAsync.
        throw "Unexpected error";
      case 12007:
        // The dialog box is already open. Thrown by call of displayDialogAsync.
        throw "Another preview dialog is open. Please close it and try again.";
      case 12009:
        // The dialog box has been blocked by a popup blocker. Thrown by call of displayDialogAsync.
        throw "We use a popup dialog to show the document preview. Please allow the popup and try again.";
      default:
        reportError(asyncResult.error.message);
    }
  } else if (!asyncResult.value) {
    reportError("Failed to open dialog for unknown reason.");
  }

  return asyncResult;
}
