import { createContext, FunctionComponent, SetStateAction, useEffect, useState, JSX } from "react";
import { DynamicElement } from "../../../lib/liquidx/internal";
import { useId } from "@fluentui/react-components";
import debug from "../../../lib/debug";
import { SelectionDetails } from "../../../lib/sync-engine/operations/GetSelection";
import { useInitialized } from "@src/taskpane/hooks/useInitialized";

interface Walter {
  documentSelectionData: SelectionDetails;
  dynamicElements: DynamicElement[];
  platform?: Office.PlatformType;
  selectedDynamicElementIds: number[];
  isFieldSetSelected: boolean;
  setDocumentSelectionData: (value: SetStateAction<SelectionDetails>) => void;
  setSelectedDynamicElementIds: (value: SetStateAction<number[]>) => void;
  setIsFieldSetSelected: (value: SetStateAction<boolean>) => void;
  toasterId: string;
  bottomDialog: JSX.Element | null;
  setBottomDialog: (value: SetStateAction<JSX.Element | null>) => void;
}

export const defaultSelectionDetails: SelectionDetails = {
  isEmpty: true,
  paragraphsCount: 0,
  paragraphTextsAreEmpty: true,
  isInTableCell: false,
  isInTable: false,
};

export const defaultWalterContext: Walter = {
  documentSelectionData: defaultSelectionDetails,
  dynamicElements: [],
  platform: undefined,
  selectedDynamicElementIds: [],
  isFieldSetSelected: false,
  setDocumentSelectionData: () => {},
  setSelectedDynamicElementIds: () => {
    debug.error("if this has been called, WalterProvider isn't being used properly");
  },
  setIsFieldSetSelected: () => {},
  toasterId: "",
  bottomDialog: null,
  setBottomDialog: () => {},
};

export const WalterContext = createContext(defaultWalterContext);

export const WalterProvider: FunctionComponent = ({ children }) => {
  const [selectedDynamicElementIds, setSelectedDynamicElementIds] = useState<number[]>([]);
  const [documentSelectionData, setDocumentSelectionData] = useState<SelectionDetails>(defaultSelectionDetails);
  const initialized = useInitialized();
  // TODO: Change how the platform is resolved (right now, this may be called before Office.context is available)
  const [platform, setPlatform] = useState<Office.PlatformType | undefined>();
  const [toasterId] = useState(useId("toaster"));
  const [isFieldSetSelected, setIsFieldSetSelected] = useState<boolean>(false);
  const [bottomDialog, setBottomDialog] = useState<JSX.Element | null>(null);

  useEffect(() => {
    if (initialized) setPlatform(Office.context.platform);
  }, [initialized]);

  return (
    <WalterContext.Provider
      value={{
        ...defaultWalterContext,
        documentSelectionData,
        platform,
        selectedDynamicElementIds,
        isFieldSetSelected,
        setDocumentSelectionData,
        setSelectedDynamicElementIds,
        setIsFieldSetSelected,
        bottomDialog,
        setBottomDialog,
        toasterId,
      }}
    >
      {children}
    </WalterContext.Provider>
  );
};
