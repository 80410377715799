import { WALTER_HOST } from "@src/lib/env-vars";
import { UserWithRecents } from "@src/lib/auth/OAuthService";
import { downloadDocx } from "@src/lib/downloadDocx";
import { defaultStorage } from "@src/lib/settings/defaultStorage";
import { tokenStorage } from "@src/lib/settings/tokenStorage";

export async function generatePreviewUrl(): Promise<string> {
  const oAuthData = await tokenStorage.get("walter");
  const currentUser = defaultStorage.get<UserWithRecents>("currentUser");

  if (!oAuthData || !currentUser) {
    onUnauthorized();
    throw new Error("User not logged in");
  }

  const accessToken = oAuthData.accessToken;
  const userId = currentUser.id.toString();

  const { slices, properties } = await downloadDocx();
  let filename = properties?.filename ?? "untitled document.docx";
  const blob = new Blob(slices, { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
  const formData = new FormData();

  if (!filename) {
    filename = "untitled document.docx";
  }

  formData.append("file", blob, filename);
  formData.append("file_name", filename);
  formData.append("user_id", userId);

  const postUrl = `${WALTER_HOST}/word/assembly_preview/`;

  const previewResponse = await fetch(postUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: formData,
  });

  if (!previewResponse.ok) {
    if (previewResponse.status === 401) {
      onUnauthorized();
      throw new Error("User not logged in");
    }

    throw new Error("Something went wrong");
  }

  const previewData: { id: number } = await previewResponse.json();
  return `${WALTER_HOST}/word/assembly_preview/?file_id=${previewData.id}&user_id=${userId}&access_token=${accessToken}`;
}

function onUnauthorized() {
  defaultStorage.clear();
  window.location.reload();
}
