import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import type { OAuthToken } from "../settings/tokenStorage";
import { WALTER_HOST, WORD_OAUTH_CLIENT_ID } from "@src/lib/env-vars";

export interface UserWithRecents {
  sgid: string;
  signed_streamName: string;
  id: number;
  name: any;
  email: string;
  initials: any;
  preferred_name: string | null;
  is_admin: any;
  is_docusign_Setup: boolean;
  gravatar_url: string;
  recent_legal_entity_id: number | null;
  recent_workspace_id: number | null;
}

export interface OAuthTokenResponse {
  tokenData: OAuthToken | "";
  currentUser: UserWithRecents;
}

export interface OAuthSignInResponse {
  url: string;
  expiresInSeconds: number;
}

export const revokeToken = async (accessToken: string) => {
  try {
    const response = await fetch(`${WALTER_HOST}/oauth/revoke`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        client_id: WORD_OAUTH_CLIENT_ID,
        token: accessToken,
      }),
    });

    try {
      if (response.ok && response.status === 200) {
        return response;
      } else {
        captureErrorInfo("Error revoking token");
        return false;
      }
    } catch (error) {
      captureErrorInfo(error);
      return false;
    }
  } catch (error) {
    captureErrorInfo(error);
  }
};

export const getNewToken = async (refreshToken: string) => {
  try {
    const response = await fetch(`${WALTER_HOST}/oauth/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        grant_type: "refresh_token",
        client_id: import.meta.env.VITE_WORD_OAUTH_CLIENT_ID,
        refresh_token: refreshToken,
      }),
    });

    if (response.ok && response.status === 200) {
      try {
        const data: OAuthToken = await response.json();
        return data;
      } catch (error) {
        captureErrorInfo(error);
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    captureErrorInfo(error);
    return null;
  }
};
