import { useEffect, useState, useContext, type FunctionComponent, type ReactNode } from "react";
import { makeStyles, shorthands, tokens, Toaster, mergeClasses } from "@fluentui/react-components";
import { useLocation } from "react-router-dom";
import BackNavigation from "./shared/BackNavigation";
import { humanize } from "../helpers/formatData";
import { WalterContext } from "../providers/walter";
import { useInitialized } from "@src/taskpane/hooks/useInitialized";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import { LoginPage } from "./LoginPage";
import Progress from "@src/taskpane/components/Progress";
import TopNav from "@src/taskpane/components/TopNav";
import { migrate } from "@src/lib/migrations/migrate";
import { useTypeOptions } from "@src/taskpane/hooks/useTypeOptions";

const useOuterStyles = makeStyles({
  container: {
    minHeight: "100vh",
  },
  centeredContainer: {
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    boxSizing: "border-box",
    "> :not(:first-child)": {
      marginTop: "0px",
    },
    "& button": {
      width: "100%",
    },
  },
  backButton: {
    ...shorthands.margin("12px", "12px", "0", "12px"),
  },
});

const useInnerStyles = makeStyles({
  paddedContent: {
    ...shorthands.padding("0", "12px"),
  },
});

const ModeBanner = () => {
  let backgroundColor = tokens.colorPaletteBerryBackground2;
  let environment: string;

  if (window.location.hostname === "word.staging.getwalter.com") {
    environment = "staging";
    backgroundColor = "lightgreen";
  } else {
    environment = import.meta.env.MODE;
  }

  return <div style={{ backgroundColor, textAlign: "center", padding: "8px" }}>{humanize(environment)}</div>;
};

const Main = ({ children, padContent }: { children: ReactNode; padContent: boolean }) => {
  const innerStyles = useInnerStyles();
  useTypeOptions();

  return padContent ? <div className={innerStyles.paddedContent}>{children}</div> : <div>{children}</div>;
};

const Layout: FunctionComponent = ({ children }) => {
  const outerStyles = useOuterStyles();

  const location = useLocation();
  const isHome = location.pathname === "/";
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const initialized = useInitialized(setIsLoggedIn);
  const isChoosingFieldSet = location.pathname === "/choose-field-set";
  const { toasterId } = useContext(WalterContext);

  // Run migrations again once we have a token (this allows migrations to defer themselves until we have a token)
  useEffect(() => {
    if (initialized && isLoggedIn) {
      void migrate("api").catch(captureErrorInfo);
    }
  }, [initialized, isLoggedIn]);

  let banner;

  if (["word.staging.getwalter.com", "localhost"].includes(window.location.hostname)) {
    banner = <ModeBanner />;
  }

  if (!initialized) {
    return (
      <div className={mergeClasses(outerStyles.container, outerStyles.centeredContainer)}>
        <Progress message="Initializing..." />
      </div>
    );
  }

  if (initialized && !isLoggedIn) {
    return <LoginPage setIsLoggedIn={setIsLoggedIn}>{banner}</LoginPage>;
  }

  return (
    <div className={outerStyles.container}>
      {banner}
      <TopNav setIsLoggedIn={setIsLoggedIn} />
      <Toaster toasterId={toasterId} position="top" pauseOnHover pauseOnWindowBlur />

      <div className={outerStyles.root}>
        {!isHome && (
          <span className={outerStyles.backButton}>
            <BackNavigation />
          </span>
        )}
        <Main padContent={!(isHome || isChoosingFieldSet)}>{children}</Main>
      </div>
    </div>
  );
};

export default Layout;
