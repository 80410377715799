import { FC, useEffect, useState } from "react";
import {
  makeStyles,
  shorthands,
  Button,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
  tokens,
  Text,
  MenuDivider,
} from "@fluentui/react-components";
import { CollapsibleSection } from "../CollapsibleSection";
import { useFields } from "@src/taskpane/hooks/useFields";
import { IndentedTree } from "./IndentedTree";
import { SchemaNode, Option, Field } from "@src/lib/schemas";
import { ArrayTreeItem } from "./ArrayTreeItem";
import { ScalarTreeItem } from "./ScalarTreeItem";
import { BetweenHorizonalEnd, CloudUpload, Ellipsis, Layers, Search, Sigma } from "lucide-react";
import { TextCombobox } from "../TextCombobox";
import { useHistory } from "react-router-dom";
import { ObjectTree } from "./ObjectTree";
import { useBottomDialog } from "@src/taskpane/hooks/useBottomDialog";
import { BottomDialog } from "../home/BottomDialog";
import { PluralizeComputedField } from "./PluralizeComputedField";
import { useNewUi } from "@src/taskpane/hooks/useNewUi";
import { AddField } from "../fields/Add";
import { EditComputedField } from "./EditVariableField";

export const useStyles = makeStyles({
  root: {
    display: "grid",
    ...shorthands.padding("0", "0"),
  },
  tree: {
    ...shorthands.padding("0", "0"),
  },
  treeItemLayout: {
    ...shorthands.padding("0", "0"),
  },
  treeItemContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    ...shorthands.gap("8px"),
  },
  treeItemFocused: {
    ...shorthands.outline("2px", "solid", tokens.colorBrandStroke1),
    outlineOffset: "-2px",
    ...shorthands.borderRadius("4px"),
  },
  searchInput: {
    width: "100%",
  },
});

export const FieldsPane: FC = () => {
  const { fields, version } = useFields();
  const [documentFields, setDocumentFields] = useState<Field[]>([]);
  const [contextualFields, setContextualFields] = useState<Field[]>([]);
  const [computedFields, setComputedFields] = useState<Field[]>([]);

  useEffect(() => {
    setDocumentFields(fields.filter((field) => field.fieldType === "document"));
    setContextualFields(fields.filter((field) => field.isContextual));
    setComputedFields(fields.filter((field) => field.isComputed));
  }, [fields, version]);

  return (
    <>
      {computedFields.length > 0 && (
        <FieldSection title="Computed field" hideSearch hideActions fields={computedFields} />
      )}
      {contextualFields.length > 0 && <FieldSection title="Loop fields" hideActions fields={contextualFields} />}
      <FieldSection title="Fields" fields={documentFields} />
    </>
  );
};

const FieldSection: FC<{ title: string; fields: Field[]; hideActions?: boolean; hideSearch?: boolean }> = ({
  title,
  fields,
  hideActions,
  hideSearch,
}) => {
  const styles = useStyles();
  const { showNewUi } = useNewUi();
  const { store } = useFields();
  const [showSearch, setShowSearch] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [focusedItem, setFocusedItem] = useState<string>("");
  const history = useHistory();
  const { openDialog, closeDialog } = useBottomDialog();

  function handleSearchClick() {
    setShowSearch(true);
  }

  useEffect(() => {
    const options = fields.flatMap((field) => {
      return field.node?.descendants.map((node) => ({ label: node.fullTitle, value: node.path }));
    });

    setOptions(options);
  }, [fields]);

  function insertVariableField() {
    const variableField = new PluralizeComputedField();

    openDialog(
      <BottomDialog title="New computed field" onOpenChange={({ open }) => !open && closeDialog()}>
        <EditComputedField computedField={variableField} onSubmit={() => closeDialog()} />
      </BottomDialog>,
    );
  }

  function handleAddField() {
    if (!showNewUi) {
      history.push("/fields/new");
      return;
    }

    openDialog(
      <BottomDialog title="New field" onOpenChange={({ open }) => !open && closeDialog()}>
        <AddField onSubmit={() => closeDialog()} />
      </BottomDialog>,
    );
  }

  const actions = (
    <>
      {!hideSearch && (
        <Button style={{ padding: 0, minWidth: 20 }} appearance="subtle" onClick={handleSearchClick}>
          <Search width="14" height="14" />
        </Button>
      )}
      {!hideActions && (
        <Menu positioning="below-end">
          <MenuTrigger>
            <Button style={{ padding: 0, minWidth: 20 }} appearance="subtle">
              <Ellipsis width="14" height="14" />
            </Button>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem onClick={handleAddField} icon={<BetweenHorizonalEnd width="16" strokeWidth="1.5" />}>
                Add field
              </MenuItem>
              <Menu>
                <MenuTrigger>
                  <MenuItem icon={<Sigma width="16" strokeWidth="1.5" />}>Add computed field</MenuItem>
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuItem onClick={insertVariableField} icon={<Layers width="16" strokeWidth="1.5" />}>
                      Pluralize
                    </MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>

              <MenuDivider />
              <MenuItem
                onClick={() => history.push("/field-sets/new")}
                icon={<CloudUpload width="16" strokeWidth="1.5" />}
              >
                Save fieldset
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      )}
    </>
  );

  function handleSearchChange(value: string) {
    // When nothing picked, don't do anything
    if (!value) return;

    setShowSearch(false);
    const schemaProperty = store.find(value);
    if (schemaProperty) {
      // Focus the item for a few seconds
      setFocusedItem(schemaProperty.path);
      setTimeout(() => setFocusedItem(""), 3000);
    }
  }

  return (
    <CollapsibleSection title={title} actions={actions}>
      {showSearch && (
        <TextCombobox
          autoFocus
          placeholder="Jump to property"
          appearance="underline"
          options={options}
          value={""}
          className={styles.searchInput}
          onChange={handleSearchChange}
          // Hide search on escape or enter
          onKeyDown={(e) => ["Escape", "Enter"].includes(e.key) && setShowSearch(false)}
        />
      )}
      <div className={styles.root}>
        {fields.map((field) => (
          <FieldTree key={field.key} field={field} focusedItem={focusedItem} />
        ))}
        {fields.length === 0 && (
          <Text italic style={{ padding: "12px", display: "flex", justifyContent: "center" }}>
            {/* TODO: add field button */}
            No fields
          </Text>
        )}
      </div>
    </CollapsibleSection>
  );
};

const FieldTree: FC<{ field: Field; focusedItem?: string }> = ({ field, focusedItem }) => {
  const node = field.node;

  if (!node) return null;
  if (node.isHidden) return null;

  return (
    <IndentedTree key={node.path} aria-label={node.title} level={0} pixelsPerLevel={24} focusedItem={focusedItem}>
      <PropertyTreeItem key={node.path} node={node} />
    </IndentedTree>
  );
};

export const PropertyTreeItem: FC<{ node: SchemaNode }> = ({ node }) => {
  if (node.isHidden) return null;

  switch (node.treeType) {
    case "object":
      return <ObjectTree node={node} />;
    case "array":
      return <ArrayTreeItem node={node} />;
    case "scalar":
      return <ScalarTreeItem node={node} />;
    default:
      throw new Error(`Unknown node treeType: ${node.treeType}`);
  }
};
