import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  makeStyles,
  shorthands,
  tokens,
  Link,
  Spinner,
} from "@fluentui/react-components";
import debug from "@src/lib/debug";
import { DynamicElement, VariableElement } from "@src/lib/liquidx/internal";
import { DynamicElementViewHelper, mapDynamicElement } from "@src/taskpane/helpers/mapDynamicElement";
import { WalterContext } from "@src/taskpane/providers/walter";
import clsx from "clsx";
import { ChevronDown, FileSearch2, PenTool } from "lucide-react";
import { JSX, FC, useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { WalterStore } from "@src/lib/properties/WalterStore";
import { tokenStorage } from "@src/lib/settings/tokenStorage";
import { revokeToken } from "@src/lib/auth/OAuthService";
import { captureErrorInfo } from "@src/taskpane/helpers/errorHandler";
import useToasts from "@src/taskpane/hooks/useToasts";
import { AddStyles } from "@src/lib/migrations/AddStyles";
import { InsertElementSubmenu } from "../FieldsPane/InsertElementSubmenu";
import { DocumentSettings } from "@src/lib/settings/DocumentSettings";
import { syncEngine } from "@src/lib/sync-engine/SyncEngine";
import { InspectCustomXmlStore } from "@src/lib/sync-engine/operations/GetValueFromCustomXmlStore";

export const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "1px",
    flexWrap: "wrap",
    position: "sticky",
    top: "0",
    overflow: "hidden",
    backgroundColor: tokens.colorSubtleBackgroundLightAlphaHover,
    borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
    backdropFilter: "blur(3px)",
    // Negate the border-bottom until toolbar is in sticky position
    marginBottom: "-1px",
    zIndex: 1,
  },
  toolbarItem: {
    ...shorthands.padding("6px", "2px", "6px", "6px"),
    ...shorthands.gap("4px"),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    "&:hover": {
      backgroundColor: tokens.colorSubtleBackgroundHover,
    },
  },
  toolbarPreviewItem: {
    ...shorthands.padding("6px", "6px", "6px", "6px"),
    ...shorthands.gap("4px"),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    "&:hover": {
      backgroundColor: tokens.colorSubtleBackgroundHover,
    },
  },
  toolbarMenuItem: {
    ...shorthands.gap("8px"),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
  },
  toolbarMenuHeader: {
    color: tokens.colorNeutralForeground3Hover,
    textTransform: "uppercase",
    fontSize: "11px",
    paddingLeft: "11px",
    fontWeight: "600",
    userSelect: "none",
    pointerEvents: "none",
  },
  toolbarItemLabel: {
    textTransform: "uppercase",
    fontSize: "10px",
    fontWeight: "600",
    userSelect: "none",
    pointerEvents: "none",
  },
});

const ToolbarMenu: FC<{
  label: string;
  icon: JSX.Element;
  items: DynamicElementViewHelper[];
  insertAround?: boolean;
}> = ({ label, icon, items, insertAround }) => {
  const styles = useStyles();

  return (
    <Menu>
      <MenuTrigger>
        <div className={styles.toolbarItem}>
          {icon}
          <ChevronDown width={10} />
        </div>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          <InsertElementSubmenu items={items} label={label} isFirst insertAround={insertAround} />
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export const insertable = {
  variables: [
    mapDynamicElement("variable/basic"),
    mapDynamicElement("variable/pluralize"),
    mapDynamicElement("variable/advanced"),
  ],
  conditionals: [
    mapDynamicElement("if/basic"),
    mapDynamicElement("if/basicWithElse"),
    mapDynamicElement("if/advanced"),
  ],
  loops: [
    mapDynamicElement("loop/basic"),
    // TODO: Implement advanced loop
    // mapDynamicElement("loop/advanced")
  ],
  arrayVariables: [mapDynamicElement("variable/pluralize")],
  scalarVariables: [mapDynamicElement("variable/basic"), mapDynamicElement("variable/advanced")],
  numberVariables: [
    mapDynamicElement("variable/basic"),
    mapDynamicElement("variable/pluralize"),
    mapDynamicElement("variable/advanced"),
  ],
  computedFields: [mapDynamicElement("variable/alias")],
  signTabs: [
    mapDynamicElement("variable/signature"),
    mapDynamicElement("variable/initials"),
    mapDynamicElement("variable/dateSigned"),
    mapDynamicElement("variable/checkbox"),
    mapDynamicElement("variable/radio"),
    mapDynamicElement("variable/text"),
  ],
};

export const Toolbar: FC<{ preview?: () => Promise<void> }> = ({ preview }) => {
  const isChoosingFieldSet = location.pathname === "/choose-field-set";
  const history = useHistory();
  const { isFieldSetSelected } = useContext(WalterContext);
  const styles = useStyles();
  const { showErrors } = useToasts();
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [autoOpen, setAutoOpen] = useState<boolean>(false);

  async function signOut() {
    const token = await tokenStorage.get("walter");

    if (token) {
      try {
        await revokeToken(token.accessToken);
        tokenStorage.clear();
      } catch (e: unknown) {
        captureErrorInfo(e);

        showErrors({
          title: "Error logging you out",
          messages: import.meta.env.DEV ? [(e as Error).message] : [],
          footerContent: <Link onClick={() => signOut()}>Try again</Link>,
        });
      }
    }

    window.location.reload();
  }

  useEffect(() => {
    async function setIt() {
      setAutoOpen(await DocumentSettings.get("Office.AutoShowTaskpaneWithDocument"));
    }

    void setIt();
  }, []);

  async function toggleAutoOpen() {
    setAutoOpen(!autoOpen);
    void DocumentSettings.set("Office.AutoShowTaskpaneWithDocument", !autoOpen);
  }

  async function handlePreview() {
    if (!preview) return;
    if (loadingPreview) return;

    setLoadingPreview(true);
    try {
      await preview();
    } finally {
      setLoadingPreview(false);
    }
  }

  return (
    <div className={styles.root} data-testid="toolbar">
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <div className={styles.toolbarItem}>
            <img
              src="https://assets.website-files.com/6466932b2078b80cd650a863/64e91971ecbf264430c05dba_Walter_Icon_256px.png"
              alt="Walter"
              style={{ width: 20, height: 20 }}
            />
            <ChevronDown width={10} />
          </div>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            {DynamicElement.all().length === 0 && (
              <MenuItem onClick={() => new VariableElement().save()}>Switch to old UI</MenuItem>
            )}
            <MenuItem
              onClick={() => void DynamicElement.rebuild()}
              title="Rebuild labels and other derived information"
            >
              Rebuild elements
            </MenuItem>
            <MenuItem onClick={() => void new AddStyles().apply()} title="Update styles provided by Walter">
              Reset styles
            </MenuItem>
            <MenuItem onClick={toggleAutoOpen} title="Open this taskpane when the file is opened">
              {autoOpen ? "Disable auto open" : "Enable auto open"}
            </MenuItem>
            {import.meta.env.DEV && (
              <>
                <MenuDivider />
                <MenuItem onClick={() => DynamicElement.all().map((el) => el.delete())}>
                  Clear content controls
                </MenuItem>
                <MenuItem onClick={() => syncEngine.perform(new InspectCustomXmlStore())}>Inspect custom XML</MenuItem>
                <MenuItem onClick={async () => debug.log("document schema", await WalterStore.getSchema())}>
                  Log schema
                </MenuItem>
              </>
            )}
            <MenuDivider />
            {!isChoosingFieldSet && isFieldSetSelected ? (
              <MenuItem onClick={() => history.push("/choose-field-set")}>Change field set</MenuItem>
            ) : null}
            <MenuItem onClick={() => history.push("/field-sets")}>Manage field sets</MenuItem>
            <MenuDivider />
            <MenuItem onClick={signOut}>Sign out</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
      <ToolbarMenu label="Variables" items={insertable.variables} icon={mapDynamicElement("variable").icon} />
      <ToolbarMenu
        label="Conditionals"
        items={insertable.conditionals}
        icon={mapDynamicElement("if").icon}
        insertAround
      />
      <ToolbarMenu label="Loops" items={insertable.loops} icon={mapDynamicElement("loop").icon} insertAround />
      <ToolbarMenu label="Sign tabs" items={insertable.signTabs} icon={<PenTool width="18" strokeWidth="1.5" />} />
      {preview && (
        <div className={clsx(styles.toolbarPreviewItem)} onClick={handlePreview}>
          {!loadingPreview && <FileSearch2 width={18} strokeWidth={1} />}
          {loadingPreview && <Spinner size="extra-tiny" style={{ paddingRight: "2px" }} />}
          <div className={styles.toolbarItemLabel}>Preview</div>
        </div>
      )}
    </div>
  );
};
