import { useEffect, useState } from "react";
import { UserDefinedFieldSet, getBuiltinFieldSets, getUserDefinedFieldSets } from "@src/lib/walter/fieldSets";
import debug from "../../lib/debug";
import { captureException } from "@sentry/react";

export const useFieldSets = () => {
  const [presetFieldSets, setPresetFieldSets] = useState<{ label: string; key: string; description?: string }[]>([]);
  const [workspaceFieldSets, setWorkspaceFieldSets] = useState<UserDefinedFieldSet[]>([]);
  const [userPersonalFieldSets, setUserPersonalFieldSets] = useState<UserDefinedFieldSet[]>([]);

  useEffect(() => {
    setPresetFieldSets(getBuiltinFieldSets());

    const { fieldSets: userDefinedFieldSets, abortController } = getUserDefinedFieldSets();

    userDefinedFieldSets
      .then(({ userPersonalFieldSets, workspaceFieldSets }) => {
        setUserPersonalFieldSets(userPersonalFieldSets);
        setWorkspaceFieldSets(workspaceFieldSets);
      })
      .catch((e) => {
        debug.error(e);
        captureException(e);
      });

    return () => {
      abortController?.abort("component unmounted");
    };
  }, []);

  return {
    presetFieldSets,
    userPersonalFieldSets,
    workspaceFieldSets,
    setPresetFieldSets,
    setWorkspaceFieldSets,
    setUserPersonalFieldSets,
  };
};
